/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
    {
        id: 1,
        message: "You are beautiful",
    },
    {
        id: 2,
        message: "May your day be filled with wonder",
    },
    {
        id: 3,
        message: "May the force be with you",
    },
    {
        id: 4,
        message: "Let your light shine",
    },
    {
        id: 5,
        message: "Hold onto your dreams",
    },
    {
        id: 6,
        message: "A pearl has a humble beginning as a grain of sand",
    },
    {
        id: 7,
        message: "Hang in there",
    },
    {
        id: 8,
        message: "That which animates your body is immortal",
    },
    {
        id: 9,
        message: "Divinity is your birthright",
    },
    {
        id: 10,
        message: "Love is all there is",
    },
    {
        id: 11,
        message: "What would love do now?",
    },
    {
        id: 12,
        message: "Your body is a temple",
    },
    {
        id: 13,
        message: "That which you give to another you give to yourself",
    },
    {
        id: 14,
        message: "Life shows up perfectly in every given moment",
    },
    {
        id: 15,
        message: "You are a wonder to behold",
    },
    {
        id: 16,
        message: "Love like you have nothing to lose",
    },
    {
        id: 17,
        message: "Honor yourself by saying no",
    },
    {
        id: 18,
        message: "You mean the world to someone",
    },
    {
        id: 19,
        message: "You are loved and supported",
    },
    {
        id: 20,
        message: "Every day brings new opportunities",
    },
    {
        id: 21,
        message: "The world is a better place because of you",
    }
]