
/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
    {
        id: 1,
        category: "Pairs",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 2,
        category: "Triples",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 3,
        category: "Evens",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 4,
        category: "Odds",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 5,
        category: "Lows",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 6,
        category: "Highs",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 7,
        category: "Straight",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: true,
        isSum: false
    },
    {
        id: 8,
        category: "3 of a Kind",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: true
    },
    {
        id: 9,
        category: "4 of a Kind",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: true
    },
    {
        id: 10,
        category: "5 of a Kind",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: true
    },
    {
        id: 11,
        category: "6 of a Kind",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: true
    },
    {
        id: 12,
        category: "7 of a Kind",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: true
    },
    {
        id: 13,
        category: "Spare Change",
        value: 0,
        isMet: false,
        isFilled: false,
        isTop: false,
        isSum: false
    }
]