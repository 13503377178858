import React from "react"

export default function Scoresheet(props) {
    let styles = {}
    if (props.isFilled) {
        styles = { backgroundColor: "#e6aae1" }
    } else if (props.isMet && props.bank) {
        styles = { backgroundColor: "#5cabab" }
    } else {
        styles = { backgroundColor: "#ece8fa" }
    }

    return (
        <tr>
            {props.bank ? (
                <td className="td-button-format"
                    style={styles}
                    onClick={props.selectCategory}
                >
                    {props.category}
                </td>
            ) : (
                    <td className="td-button-format"
                        style={styles}
                    >
                        {props.category}
                    </td>
            )}
            <td>{props.value}</td>
        </tr>
    )
}
