/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
    {
        id: 1,
        value: 0,
        isHeld: false,
        isRed: true
    },
    {
        id: 2,
        value: 0,
        isHeld: false,
        isRed: false
    },
    {
        id: 3,
        value: 0,
        isHeld: false,
        isRed: false
    },
    {
        id: 4,
        value: 0,
        isHeld: false,
        isRed: false
    },
    {
        id: 5,
        value: 0,
        isHeld: false,
        isRed: false
    },
    {
        id: 6,
        value: 0,
        isHeld: false,
        isRed: false
    },
    {
        id: 7,
        value: 0,
        isHeld: false,
        isRed: false
    }
]