import React from "react"

export default function Scoresheet(props) {
    return (
        <tr>
            <td>{props.category}</td>
            <td>{props.value}</td>
        </tr>
    )
}
