import React from "react"
import { Link } from 'react-router-dom'

export default function Navbar(props) {

    return (
        <>
            {props.isMobile ? (
                <header>
                    <nav>
                        <Link to="/rules">Rules</Link>
                        <Link to="/play">How To Play</Link>
                    </nav>
                </header >
            ) : (
                <header>
                    <Link className="site-logo" to="/">#BankRoll</Link>
                    <nav>
                        <Link to="/rules">Rules</Link>
                        <Link to="/play">How To Play</Link>
                    </nav>
                </header >
            )}
        </>
    )
}
