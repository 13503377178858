import React from "react"
import { useNavigate } from 'react-router-dom';

export default function Rules() {
    const navigate = useNavigate();

    let styles = {}
    styles = { backgroundColor: "#e6aae1" }

    function toGame() {
        navigate("/")
    }

    return (
        <main>
            <h1 className="title">Rules</h1>
            <div className="rules">
                <h3 className="rules-h3">Bank Roll</h3>
                <p>
                Bank Roll is a dice game played with 7 dice; 6 white and one red. The game consists of thirteen rounds.
                </p>
                <h3 className="rules-h3">Object of the game</h3>
                <p>
                The object of Bank Roll is to get the highest score by completing the thirteen categories specified below.
                </p>
                <h3 className="rules-h3">Fixed Point Categories</h3>
                <p>
                    Fixed point categories are worth 30 points each and only 6 dice are needed to complete these categories. The exception is 7 of a kind which is worth 75 points.
                </p>
                <div className = "rules-container">
                    <p style={styles}>
                        3 Pairs
                    </p>
                    <p>
                        3 sets of two matching dice
                    </p>
                    <p>
                        Can be 4 of a kind and a pair
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        2 Triples
                    </p>
                    <p>
                        2 sets of three matching dice
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        Evens   
                    </p>
                    <p>
                        6 even dice
                    </p>
                    <p>
                        Die face values - 2,4,6
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        Odds
                    </p>
                    <p>
                        6 odd dice
                    </p>
                    <p>
                        Die face values - 1,3,5 
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        Lows
                    </p>
                    <p>
                        6 low numbered dice
                    </p>
                    <p>
                        Die face values - 1,2,3
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        Highs
                    </p>
                    <p>
                        6 high numbered dice
                    </p>
                    <p>
                        Die face values - 4,5,6
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        Straight
                    </p>
                    <p>
                        6 dice in sequence
                    </p>
                    <p>
                        Die face values - 1,2,3,4,5,6
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        7 of a Kind
                    </p>
                    <p>
                        7 dice of the same denomination
                    </p>
                </div>
                <h3 className="rules-h3">Variable Point Categories</h3>
                <p>
                    Variable point categories are calculated by adding up the face value of the scoring dice. For example, if a player rolls 3 sixes to complete "3 of a Kind" the player earns 18 points (3 times 6). The remaining 4 dice are not included in the score. Spare Change is the exception; being the sum of the face value of all seven dice.
                </p>
                <div className="rules-container">
                    <p style={styles}>
                        3 of a Kind
                    </p>
                    <p>
                        3 dice of the same denomination
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        4 of a Kind
                    </p>
                    <p>
                        4 dice of the same denomination
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        5 of a Kind
                    </p>
                    <p>
                        5 dice of the same denomination
                    </p>
                </div>
                <div className="rules-container">
                    <p style={styles}>
                        6 of a Kind
                    </p>
                    <p>
                        6 dice of the same denomination
                    </p>
                </div>
                <h3 className="rules-h3">Rolling</h3>
                <p>
                    A turn typically consists of 3 rolls. To start a turn, the player takes all 7 dice and rolls them. The player can re-roll some or all the dice, as many as they decide, two more times; reserving any of the dice they would like to keep. A player's turn is ended when 
                    <ul>
                        <li>They have completed 3 rolls (unless using banked rolls is involved), or</li>
                        <li>They have completed a category (and they choose to end the turn).</li>
                    </ul>
                    A player may change strategy in the middle of a turn if desired, and re-roll some or all of the dice they have previously reserved.  Each category can be used only once per game, but there is no prescribed order in which they must be filled.  The player may not pass, but must enter a score in one of the 13 categories (even if that score is a zero) in order to end their turn. The player may not change the score in a category once it has been filled. 
                    <br></br>
                    <br></br>
                    Each turn must include the red die in order to be scored. For example, if the player, after 3 turns has managed to roll 3 pairs, or odds, or any other scoreable combination on the white dice but the red dice is not included in the combination, this turn cannot be scored (other than in the "Spare Change" category, if that category is still open). By way of further explanation if the player throws 3 sixes on white dice on their first turn, they cannot score this in 3 of a Kind. They could only score this roll in that category if one of the sixes is the red dice.
                    <br></br>
                    <br></br>
                    If the player fails to complete a category the player would then mark zero in one of the open categories of their choosing on their scoresheet. The player does not have to mark zero in the category they were rolling for. 
                </p>
                <h3 className="rules-h3">Banking</h3>
                <p>
                    If a player completes a category in less than 3 rolls the player can choose to bank the remaining rolls. At the start of their turn the player can choose not to roll at all, enter zero for a category and bank 3 rolls. 
                    <br></br>
                    <br></br>
                    After having used their three allotted rolls, a player may extend their turn by using any banked rolls from previous play. A player is not limited to the number of banked rolls that may be used in a single turn, except that the number of rolls cannot exceed the number of banked rolls. For example, if, at the beginning of their turn, the player has eight banked rolls, the player may roll the dice 11 times in a row before ending their turn. The player would use 3 regular rolls plus 8 banked rolls to roll 11 times. 
                    <br></br>
                    <br></br>
                    If a player has completed their 3 regular rolls and has not completed a category, they may extend their turn by using banked rolls to try to complete a category. If they successfully complete a category by using banked rolls, the turn is scored as per regular play. As in regular play, a player may change categories while using banked rolls.
                </p>
            </div>
            <button
                className="button-format"
                onClick={toGame}
            >
                Game
            </button>
        </main>
    )
}