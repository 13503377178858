import React from "react"
import { useNavigate } from 'react-router-dom';

export default function HowToPlay() {
    const navigate = useNavigate();

    function toGame() {
        navigate("/")
    }

    return (
        <main>
            <h1 className="title">How To Play</h1>
            <div className="howtoplay">
                <ul>
                    <li>To begin a turn press the Roll button to roll the dice.</li>
                    <li>Click on any dice you want to keep. You can toggle the die on and off.</li>
                    <li>Any categories that are matched will appear at the bottom of the screen.</li>
                    <li>If you want to bank press the "Bank" button. The more (earlier) you bank the better.</li>
                    <li>After clicking Bank the Scoresheet page will display.</li>
                    <li>"Met" categories are highlighted in dark green.</li>
                    <li>Previously completed categories are highlighted in purple.</li>
                    <li>Select the "met" category you want to complete by clicking on the category name.</li>
                    <li>The game page will display and a new turn will begin. Any unused rolls will be banked.</li>
                    <li>If you run out of "rolls" and "banks" the Roll button will be disabled and you must Bank.</li>
                    <li>You will score zero for the item you select to bank (as no category has been met).</li>
                    <li>Switch to the Scoresheet page by clicking the "Scoresheet" button to view completed categories.</li>
                    <li>Switch back to the Game page clicking the "Game"" button.</li>
                    <li>The game is complete when all 13 categories have been completed</li>
                    <li>Click on the "New Game" button to start a new game</li>
                    <li>If you are only rolling for Kinds and Spare Change the game will autoselect the dice for you.</li>
                    <li>However, you must select the red dice for this to happen (and any matching dice on the first roll)</li>
                </ul>
            </div>
            <button
                className="button-format"
                onClick={toGame}
            >
                Game
            </button>
        </main>
    )
}