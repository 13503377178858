import React from "react"

export default function Die(props) {
    const styles = {
        backgroundColor: props.isHeld ? "#F0EAD6" : "white"
    }

    const redStyles = {
        backgroundColor: props.isHeld ? "#960018" : "red",
        color: "white"
    }

    return (
        <div
            className="die-face"
            style={props.isRed ? redStyles : styles}
            onClick={props.holdDice}
        >
            <h2 className="die-num">{props.value}</h2>
        </div>
    )
}

