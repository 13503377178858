import React from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Game from "./game/Game"
import Scoresheet from "./scoresheet/Scoresheet"
import Rules from "./Rules/Rules"
import HowToPlay from "./Rules/HowToPlay"
import Dice from "./data/Dice"
import Category from "./data/Categories"
import Navbar from "./navigation/Navbar"
import AdsComponent from './adverts/AdsComponent';

export default function App() {

    const [categories, setCategories] = React.useState(Category)
    const [dice, setDice] = React.useState(Dice)
    const [rolls, setRolls] = React.useState(3)
    const [bankedRolls, setBankedRolls] = React.useState(0)
    const [totalScore, setTotalScore] = React.useState(0)
    const [endTurn, setEndTurn] = React.useState(false)
    const [highScore, setHighScore] = React.useState(0)

    const useMobileDetector = () => {
        const [isMobile, setIsMobile] = React.useState(false);

        React.useEffect(() => {
            const mediaQuery = window.matchMedia('(max-width: 767px)');
            const handleMediaQueryChange = (event) => {
                setIsMobile(event.matches);
            };

            mediaQuery.addEventListener('change', handleMediaQueryChange);

            // Set initial value
            setIsMobile(mediaQuery.matches);

            return () => {
                mediaQuery.removeEventListener('change', handleMediaQueryChange);
            };
        }, []);

        return isMobile;
    };

    const isMobile = useMobileDetector();

    return (
        <Router>
            <div>
                <Navbar
                    isMobile={isMobile}
                />
                <div className="adContainer">
                    {!isMobile &&
                        <div className="panel left-panel">
                            <AdsComponent dataAdSlot='1084128447' />
                        </div>
                    }
                    <Routes>
                        <Route exact path="/" element={
                            <Game
                                categories={categories}
                                setCategories={setCategories}
                                dice={dice}
                                setDice={setDice}
                                rolls={rolls}
                                setRolls={setRolls}
                                bankedRolls={bankedRolls}
                                setBankedRolls={setBankedRolls}
                                totalScore={totalScore}
                                setTotalScore={setTotalScore}
                                endTurn={endTurn}
                                setEndTurn={setEndTurn}
                                highScore={highScore}
                                setHighScore={setHighScore}
                                isMobile={isMobile}
                            />}
                        />
                        <Route path="/scoresheet" element={
                            <Scoresheet
                                categories={categories}
                                setCategories={setCategories}
                                totalScore={totalScore}
                                setTotalScore={setTotalScore}
                                endTurn={endTurn}
                                setEndTurn={setEndTurn}
                                highScore={highScore}
                                setHighScore={setHighScore}
                            />}
                        />
                        <Route path="/rules" element={
                            <Rules />}
                        />
                        <Route path="/play" element={
                            <HowToPlay />}
                        />
                    </Routes>
                    {!isMobile &&
                        <div className="panel right-panel">
                            <AdsComponent dataAdSlot='2441442145' />
                        </div>
                    }
                </div>
            </div>
        </Router>
    )
}
