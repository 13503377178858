import React from 'react';

const Message = ({ text, type, style }) => {
  // Define classNames or inline styles based on `type` (optional)
  const messageStyles = {
    success: { color: 'green', border: '1px solid green', },
    error: { color: 'red', border: '1px solid red', },
    info: { color: 'blue', border: '1px solid blue', },
  };

    // Combine provided styles with default type-based styles
    const defaultStyle = {
        padding: '10px',
        backgroundColor: '#f0f0f0', // Light grey background
        borderRadius: '10px',       // Rounded corners
    }

    const combinedStyles = { ...messageStyles[type], ...style, ...defaultStyle };

  return <div style={combinedStyles}>{text}</div>;
};

export default Message;
